import './AboutContentStyle.css'

import React from 'react';
import { Link } from "react-router-dom";
import About2 from '../assets/About2.webp'
import About1 from '../assets/About1.webp'
// import image for img.src

const AboutContent = () => {
    return (
      <div className="about">
        <div className="left">
          <h1>Who Am I?</h1>
          <p>
          I have experience with HTML, CSS, JavaScript and am always eager to learn new skills and techniques.
          </p>
          <Link to="/contact">
            <button className="btn">Contact</button>
          </Link>
        </div>
  
        <div className="right">
          <div className="img-container">
            <div className="img-stack top">
              <img src={About1} className="img" alt="true" />
            </div>
            <div className="img-stack bottom">
              <img src={About2} className="img" alt="true" />
            </div>
          </div>
        </div>
      </div>
    );
  };

export default AboutContent