import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import MainImg2 from '../components/MainImg2'
import AboutContent from '../components/AboutContent'

const About = () => {
  return (
    <div className=''>
    <Navbar />
    <MainImg2 heading="About me" text="I am a front-end developer with a passion for creating websites." />
    <AboutContent text="I have experience with HTML, CSS, JavaScript and am always eager to learn new skills and techniques."/>
    <Footer />
    </div>
  )
}
// props.text for about.content
// images for about content
export default About