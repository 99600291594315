import React from 'react'
import Navbar from '../components/Navbar'
import MainImg from '../components/MainImg'
import Footer from '../components/Footer'

const Home = () => {
  return <div className=''>
      <Navbar />
      <MainImg />
      <Footer />
    </div>
  
}

export default Home