import "./MainImgStyle.css"
import React from 'react'
import Photo1 from '../assets/Photo1.webp'
import { Link } from "react-router-dom"

 const MainImg = () => {
    return (
        <div className="hero">
          <div className="mask">
            <img className="intro-img" src={Photo1} alt="IntroImg" />
          </div>
          <div className="content">
            <p>HI, MY NAME IS <em><strong>VOLODYMYR</strong></em></p>
            <br />
            <p>I am a </p>
            <h1>Front-End Developer</h1>
            <div>
              <Link to="/project" className="btn">
                Projects
              </Link>
              <Link to="/contact" className="btn btn-light">
                Contact
              </Link>
            </div>
          </div>
        </div>
      );
 }
 
 export default MainImg