import './WorkCardStyle.css'

import React from 'react'
import { NavLink } from 'react-router-dom'
import weatherApp from '../assets/weatherApp.webp';
import pongGame from '../assets/pongGame.webp';
import landingPage from '../assets/landingPage.webp'
import StoicGenerator from '../assets/StoicGenerator.webp'
import PomodoroClock from '../assets/PomodoroClock.webp'
import Calculator from '../assets/Calculator.webp'
import nftflow from '../assets/nftflow.webp'
import camvid from '../assets/camvidpic.webp'
import jblresell from '../assets/jblresellpic.webp'

// add props, image and description for projects
// + more projects
const WorkCard = () => {
  return <div className='work-container'>
   <h1 className='project-heading'>Projects</h1>
   <div className='project-container'>
   <div className='project-card'>
        <img src= {jblresell} alt='JBL Resell' />
        <h2 className='project-title'>JBL Resell Store</h2>
        <div className='project-details'>
            <p>E-commerce store of JBL products</p>
            <div className='project-btns'>
                <NavLink to='https://www.jblresell.store/' className="btn">
                View
                </NavLink>
            </div>
        </div>
    </div>

   <div className='project-card'>
        <img src= {camvid} alt='Camvid' />
        <h2 className='project-title'>CCTV Site</h2>
        <div className='project-details'>
            <p>Experience modern surveillance solutions with advanced CCTV systems</p>
            <div className='project-btns'>
                <NavLink to='https://github.com/IMorkovkaI/camvid-site' className="btn">
                View Source
                </NavLink>
            </div>
        </div>
    </div>

   <div className='project-card'>
        <img src= {nftflow} alt='NFTflow' />
        <h2 className='project-title'>NFTflow</h2>
        <div className='project-details'>
            <p>Website for legalization of production of NFT-projects</p>
            <div className='project-btns'>
                <NavLink to='https://nftflow.net' className="btn">
                View
                </NavLink>
            </div>
        </div>
    </div>

    <div className='project-card'>
        <img src= {weatherApp} alt='Weather App' />
        <h2 className='project-title'>Weather App</h2>
        <div className='project-details'>
            <p>Weather application using API</p>
            <div className='project-btns'>
                <NavLink to='https://codepen.io/IMorkovkaI/pen/OJBVEOz' className="btn">
                View
                </NavLink>
                <NavLink to='https://github.com/IMorkovkaI/appweatherproject' className="btn">
                Source
                </NavLink>
            </div>
        </div>
    </div>
   
   
    <div className='project-card'>
        <img src= {pongGame} alt='Pong game' />
        <h2 className='project-title'>Pong game</h2>
        <div className='project-details'>
            <p>Try to beat AI in this simple game</p>
            <div className='project-btns'>
                <NavLink to='https://codepen.io/IMorkovkaI/pen/zYmGjey' className="btn">
                View
                </NavLink>
                <NavLink to='https://github.com/IMorkovkaI/pong-game' className="btn">
                Source
                </NavLink>
            </div>
        </div>
    </div>
    <div className='project-card'>
        <img src= {landingPage} alt='Kickboxing gym landing page' />
        <h2 className='project-title'>Kickboxing gym page</h2>
        <div className='project-details'>
            <p>Simple gym landing page</p>
            <div className='project-btns'>
                <NavLink to='https://codepen.io/IMorkovkaI/pen/EGxwye' className="btn">
                View
                </NavLink>
                <NavLink to='https://github.com/IMorkovkaI/first-landing-page' className="btn">
                Source
                </NavLink>
            </div>
        </div>
    </div>
    <div className='project-card'>
        <img src= {StoicGenerator} alt='Stoic quote generator' />
        <h2 className='project-title'>Stoic quote generator</h2>
        <div className='project-details'>
            <p>Generate famous stoic quotes</p>
            <div className='project-btns'>
                <NavLink to='https://codepen.io/IMorkovkaI/pen/poZgMpW' className="btn">
                View
                </NavLink>
                <NavLink to='https://github.com/IMorkovkaI/stioc-quote-generator' className="btn">
                Source
                </NavLink>
            </div>
        </div>
    </div>
    <div className='project-card'>
        <img src= {PomodoroClock} alt='Pomodoro Clock' />
        <h2 className='project-title'>Pomodoro Clock</h2>
        <div className='project-details'>
            <p>Click to start timer with preset break-times</p>
            <div className='project-btns'>
                <NavLink to='https://codepen.io/IMorkovkaI/pen/XWBqQwm' className="btn">
                View
                </NavLink>
                <NavLink to='https://github.com/IMorkovkaI/pomodoro-clock/tree/main' className="btn">
                Source
                </NavLink>
            </div>
        </div>
    </div>
    <div className='project-card'>
        <img src= {Calculator} alt='Calculator' />
        <h2 className='project-title'>Calculator</h2>
        <div className='project-details'>
            <p>Just a calculator</p>
            <div className='project-btns'>
                <NavLink to='https://codepen.io/IMorkovkaI/pen/XWBqQwm' className="btn">
                View
                </NavLink>
                <NavLink to='https://github.com/IMorkovkaI/calculator-prototype' className="btn">
                Source
                </NavLink>
            </div>
        </div>
    </div>
   </div>
   </div>
}

export default WorkCard