import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import MainImg2 from '../components/MainImg2'
import WorkCard from '../components/WorkCard'

const Project = () => {
  return (
    <div className=''>
    <Navbar />
    <MainImg2 heading="PROJECTS" text="Some of my recent works" />
    <WorkCard />
    <Footer />

    </div>
  )
}

export default Project