import "./FooterStyle.css"

import React from 'react'
import { FaFacebook, FaGithub, FaHome, FaLinkedin, FaMailBulk, FaPhone, FaTelegram, FaTwitter } from "react-icons/fa"
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <div className="footer">
        <div className="footer-container">
        <div className="left">
            <div className="location">
            <h4><FaHome size={20} style={{color:'white', marginRight: '2rem'}} />
            Zvonickova 1928/7,
            Prague</h4>
            </div>
            
            <div className="phone">
            <h4><FaPhone size={20} style={{color:'white', marginRight: '2rem'}} />
                +420 774 889 559</h4>
            </div>

            <div className="e-mail">
                <h4><FaMailBulk size={20} style={{color:'white', marginRight: '2rem'}} />
                imarkovychi@gmail.com</h4>
            </div>
            </div>
            </div>
            <br />
                <h1>Social links</h1>
                <div className="social"> 
                
            <div className="contact-me-link">
                <a href="https://codepen.io/IMorkovkaI/" target="_blank" id="profile-link"><img src="https://img.icons8.com/ios-filled/256/codepen.png" alt="Codepen" /></a>
                </div>
            <div className="contact-me-link">
                <a href="https://www.facebook.com/imorkovkai/" target="_blank" id="profile-link"><img src="https://img.icons8.com/material-rounded/256/facebook-new.png" alt="Facebook" /></a>
                </div>
            <div className="contact-me-link">
                <a href="https://t.me/IMorkovkaI" target="_blank" id="profile-link"><img src="https://img.icons8.com/ios-filled/256/telegram.png" alt="Telegram" /></a>   
                </div>
            <div className="contact-me-link">
                <a href="https://twitter.com/IMorkovkaI" target="_blank" id="profile-link"><img src="https://img.icons8.com/material-sharp/256/twitter.png" alt="Twitter" /></a>
            </div>
            <div className="contact-me-link">
                <a href="https://www.linkedin.com/in/volodymyr-markovych-263b92267/" target="_blank" id="profile-link"><img src="https://img.icons8.com/ios-glyphs/256/linkedin-circled.png" alt="LinkedIn" /></a>
            </div>
            <div className="contact-me-link">
                <a href="https://github.com/IMorkovkaI" target="_blank" id="profile-link"><img src="https://img.icons8.com/ios-glyphs/256/github.png" alt="GitHub" /></a>
            </div>
                </div>
            
        </div>
    
  )
}

export default Footer