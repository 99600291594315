import './FormStyle.css'

import React from 'react'

//email checker + sender,add


const Form = () => {
  return <div className='form'>
    <form action="https://formsubmit.co/402b6d2e3639534e93f4101be2957893" method="POST">
        <label>Your name</label>
        <input required type="text" name='name'></input>
        <label>E-mail</label>
        <input required type="email" name='email'></input>
        <label>Subject</label>
        <input required type="text"></input>
        <label>Message</label>
        <textarea name="message" rows="6" placeholder='Type your message here' required></textarea>
        <button className='btn'>Submit</button>
    </form>
  </div>
}

export default Form